

.user-page-list-item{
    font-family: Grold-light;
    color: black;
    font-size: 20px;
    cursor: pointer;
    transition: all 300ms ease;
}
.user-page-list-item:hover{
    background-color: rgba(209, 213, 219);
}