.image-bank-card-panel{
    position: relative;
    display: none;
    width: 100%;
    top: 50%;
    left: 35%
}

.image-bank-card-panel--active{
    display: block;
}

.image-bank-image{
    opacity: 1;
}

.image-bank-image--blurry{
    opacity: 0.1;
}
