.App {
  font-family: sans-serif;
  text-align: center;
}

.wrapper-container{
  justify-content: center;
}

@media screen and (max-width: 1300px ) {
  .wrapper-container{
    margin-left: 3rem;
    justify-content: start;
  }
}

.content-header{
  margin-left: 10%;
  width: 80%;
  /*max-width: 90rem;*/
}

@media screen and (max-width: 1300px ) {
  .content-header{
    margin-left: 0;
  }
}


.text-header{
  @apply text-mainFont;
  font-family: Grold-extrabold;
  font-size: 3rem;
  font-weight: 700;
}

.text-subheader{
  color: black;
  font-family: Grold-bold;
  font-size: 1.5rem;
}

.text-normal{
  color: black;
  font-family: Grold-light;
  font-size: 1.1rem;
}

.text-large{
  color: black;
  font-family: Grold-bold;
  font-size: 2.0rem;
}