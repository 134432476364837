
.button-div{
    position: absolute;
}


@keyframes slide-in {
    from{
        transform: translateY(-10%);
        opacity: 0.5;
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}


.action-dropdown{
    border: 3px solid lightgray;
    background-color: white;
    margin-top: 50px;
    width: 250px;
    top: 0;
    right: 20px;
    /*right: 18%;*/
    text-align: start;
    position: absolute;
    transition: 1s ease all;
    animation: slide-in 0.5s;
    z-index:9999;
    border-radius: 10px;
}

.action-dropdown:before {
    content:"";
    position: absolute;
    right: 11px;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent lightgray transparent;
    z-index:9999;
}

.list-alternative{
    user-select: none;
    cursor: pointer;
    margin-bottom: 5px;
    transition: 0.5s all ease;
}

.list-alternative:first-child{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.list-alternative:last-child{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 0;
}

.list-alternative:hover{
    background-color: lightgray;
}