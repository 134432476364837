/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --small-screen-threshold: 1300px;
}

.input-normal {
    @apply mb-3 text-gray-700 rounded p-2 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-opacity-75;
  }
html {
  overflow-y: scroll;
}
body {
  font-family: Grold-light;
  color: white;
}

@font-face {
  font-family: 'Grold-bold';
  src: local('Grold-bold'), url(./assets/fonts/Grold-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Grold-extrabold';
  src: local('Grold-extrabold'), url(./assets/fonts/Grold-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Grold-light';
  src: local('Grold-light'), url(./assets/fonts/Grold-Light.ttf) format('truetype');
}

.input-label-normal {
  @apply font-bold text-xl mb-1 text-mainFont;
}

.input-label-light {
  @apply font-bold text-xl mb-1 text-white;
}

.filter-green{
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}



.dropdown-button{
  @apply inline-flex justify-center rounded-md border
   border-gray-300 shadow-sm bg-white hover:bg-gray-300;

  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  user-select: none;
  cursor: pointer;
  font-family: Grold-light;
  color: black;
  font-size: 1.3rem;
  padding: 0.3rem 1rem 0 1rem;
}


@media screen and (max-width: 1000px) {
  .dropdown-button{
    font-size: 1rem;
  }
}
@media screen and (max-width: 820px) {
  .dropdown-button{
    font-size: 0.8rem;
  }
}


/*::-webkit-scrollbar {*/
/*  width: 12px;*/
/*}*/

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px     rgba(0,0,0,0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px     rgba(0,0,0,0.5);
}


.flex-one{
  flex:1;
}

.flex-two{
  flex:2;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}