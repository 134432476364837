.login-page{
    width: 100%;
    height:100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
     /*background: url("assets/rezto_logo_black.png") no-repeat bottom center fixed; */
  /*-webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
  background-color: #F7F7F2;
}
.login-box{
    min-width: 600px;
    width: 600px;
    margin-bottom: 20vh;
}

.log-box{
    min-width: 300px;
}
