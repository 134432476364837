@keyframes slide-in-left {
    0%{
        left: -200px;
    }
    100%{
        left: 0;
    }
}

.chevron-normal{
    transform: rotate(180deg) scale(1);
    transition: 0.2s;
}
.chevron-rotated{
    transform: rotate(360deg) scale(1);
    transition: 0.2s;
}


.drawer-button {
    position: fixed;
    left: 0;
    top: 20px;
    content: "\2630";
    font-size: 40px;
    cursor: pointer;
    color: black;
    transition: all .2s ease-in-out;
}

.drawer-div{
    left: 0;
    top: 0;
    z-index: 100;
    background-color: white;
    animation: slide-in-left 0.2s;


}
/* new */

