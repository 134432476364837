@keyframes slide-from-top {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/*The dropdown*/
.vertical-nav {
    position: absolute;
    height: auto;
    list-style: none;
    width: 230px;
    margin: 0 0 0 0;
    left: 110%;
    top: 8%;
    /*padding-bottom: 6px;*/
    animation: slide-from-top 0.4s;
    background-color: white;
    border: 3px solid lightgray;
}

.vertical-nav:before {
    content:"";
    position: absolute;
    left: -30px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 15px 15px 15px;
    border-color: transparent lightgray transparent transparent;
    z-index:9999;
}

.restaurant-chain-list-item{
    height: 40px;
    padding: 8px 0;
    color: #1f2329;
    text-align: center;
    float: left;
    clear: both;
    width: 100%;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
}

.restaurant-chain-list-item:last-child{
    border-bottom: 0px solid lightgray;
}
.restaurant-chain-list-item:hover{
    background-color: lightgray;

}

.restaurant-list-item{
    height: 40px;
    padding: 5px 0;
    background-color: white;
    text-align: center;
    display: inline-block;
    float: left;
    clear: both;
    color: #1f2329;

}

.restaurant-list-item:hover{
    background-color: #c4c2c2;
}




@media screen and (max-width: 500px) {
    .vertical-nav {
        right: 0;
        width: 120px;
        overflow: hidden;
    }
    .vertical-nav li {
        width: 120px;
        overflow: hidden;
    }
}



.vertical-nav li:hover {
    transition-duration: 300ms;

}


@keyframes slide-from-right {
    0% {
        right: 80%;
    }
    100% {
        right: 100%;
    }
}

.vertical-nav li ul {
    display: none;
    margin-top: 10px;
    padding: 0;
}

.vertical-nav li:hover ul {
    display: block;
}

.vertical-nav li:hover .sub-menu {
    position: relative;
    margin-top: -39px;
}


.sub-menu li {
    position: relative;
    display: block;
    top: 0;
    left: 100%;
    border: 1px solid lightgray;
    width: 100%;
    white-space: nowrap;
    z-index: 1;
    padding-right: 10px;
    animation: slide-from-right 0.1s;
    cursor: pointer;
}
