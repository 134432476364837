

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

*,
*::before,
*::after {
    box-sizing: border-box;
}

.sec-center {
    position: relative;
    max-width: 100%;
    text-align: center;
    z-index: 99999;
}

.section-dropdown {
    position: absolute;
    padding: 10px;
    background-color: white;
    color: black;
    z-index: 99999;
    top: 70px;
    left: -110px;
    width: 150px;
    border-radius: 4px;
    display: block;
    box-shadow: 0 14px 35px 0 rgba(0, 0, 0, 0.4);
    opacity: 0;
    pointer-events: none;
    transform: translateY(20px);
    transition: all 200ms linear;
}

.dropdown-selected{
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
}
/*.dropdown:checked ~ .section-dropdown{*/
/*    opacity: 1;*/
/*    pointer-events: auto;*/
/*    transform: translateY(0);*/
/*}*/
.section-dropdown:before {
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 20px;
    content: '';
    display: block;
    z-index: 99999999;
}

/*Top arrow*/
.section-dropdown:after {
    position: absolute;
    top: -7px;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #5e4949;
    content: '';
    display: block;
    z-index: 999999999999;
    transition: all 200ms linear;
}
.dark-light:checked ~ .sec-center .section-dropdown:after {
    border-bottom: 8px solid #fff;
}

.profile-drop-down-item-container{
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-right: 10px;
    transition: all 200ms linear;
    cursor: pointer;
}

.profile-drop-down-item-container:hover{

    background-color: rgba(209, 213, 219);
}


.profile-drop-down-menu {
    width: 100%;
    position: relative;
    color: black;
    transition: all 200ms linear;
    font-family: Grold-extrabold;
    font-weight: 500;
    font-size: 15px;
    border-radius: 2px;
    margin: 2px 0;
    text-align: left;
    text-decoration: none;
    display: -ms-flexbox;
    display: flex;
}



@media screen and (max-width: 991px) {
    .logo {
        top: 30px;
        left: 20px;
    }
    .dark-light:checked + label,
    .dark-light:not(:checked) + label{
        top: 20px;
        right: 20px;
    }
}