.statistics-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width: 800px ) {
    .statistics-header{
        flex-direction: column;
    }
}

.statistic-card-row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.statistic-card {
    width: 20%;
    max-width: 350px;
    min-width: 250px;
}