
.product-card {
    @apply mr-8 mb-2 transform duration-100 hover:scale-105 shadow-lg;
    max-width: 400px;
    min-width: 250px;
    height: 250px;
    width: 280px;
    position: relative;
    background-color: #fff;
}

.product-card:hover {
    z-index: 30;
    opacity: 1;
}

.active-circle {
    background: green;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.disabled-circle {
    background: red;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}