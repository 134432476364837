.report-list-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width: 800px ) {
    .report-list-header{
        flex-direction: column;
    }
}