spane {
  background-color: red;
  content: "\22EE";
}
.menu-form-hidden{
  visibility:hidden;
  opacity:0;
  transition:visibility 0s linear 0.5s,opacity 0.5s linear;
}

.menu-form-visible {
  visibility:visible;
  opacity:1;
  transition:visibility 0s linear 0.5s,opacity 0.5s linear;
  transition-delay:0s;

}